import React from 'react'
import { LocaleTypes } from '../data/locales';
import IndexLayout from '../layouts'
import theme from '../styles/theme'
import Formulario from '../components/Form';
import Footer from '../components/Footer';
import '../styles/styles.scss'
import SEO from '../components/SEO';
interface FormProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const formLead: React.FC<FormProps> = ({ pathContext, location }) => {

  const content = (
    <> 
      <SEO title="Partner tecnológico Transformación Digital - Ingenio Kushki"/>
      <Formulario />
      <div className="ingenio-bg-footer">
        <Footer />
      </div>
    </>
  );

  return <IndexLayout content={content} location={location}  />
}

export default formLead