import React, {useState} from "react";
import {  Controller, useForm, SubmitHandler } from "react-hook-form";
import axios from 'axios';
import ModalMessage from "./Modal";
import {
  Container,
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Grid,
  Select,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  FormHelperText,
  OutlinedInput
} from "@mui/material";
import './styles.scss'
import { NoEncryption } from "@mui/icons-material";

const enum CollaboratorsEnum{
  micro = 'De 0 a 10 empleados',
  small= 'De 11 a 100 empleados',
  medium = 'De 101 a 500 empleados',
  enterprise = 'Más de 500 empleados'
}

const collaborators = [
  // '¿Cuál es el número de colaboradores en al empresa o entidad?',
  'Entre 1 y 99 empleados',
  'Entre 100 a 999 empleados',
  'Entre 1000 a 4999 empleados',
  'Más de 5000 empleados'
]

const enum ProcedureEnum{
  cantDeTramites1 = 'Menos de 20% son transacciones digitales mensuales',
  cantDeTramites2 = 'Entre 21% y 40% son transacciones digitales mensuales',
  cantDeTramites3 = 'Entre 41% y 60% son transacciones digitales mensuales',
  cantDeTramites4 = 'Entre 61% y 80% son transacciones digitales mensuales',
  cantDeTramites5 = 'Entre 81% y 100% son transacciones digitales mensuales'
}

const procedures = [
  // '¿Qué porcentaje de transacciones mensuales son digitales?',
  'Menos de 20% son transacciones digitales mensuales',
  'Entre 21% y 40% son transacciones digitales mensuales',
  'Entre 41% y 60% son transacciones digitales mensuales',
  'Entre 61% y 80% son transacciones digitales mensuales',
  'Entre 81% y 100% son transacciones digitales mensuales'
]

interface FormInput {
  name: String; // nombre y apellido
  position: String; // cargo
  email: String; // email corporativo
  phone: Number; // Número telefónico
  enterprise: String; // empresa o entidad
  percentage: String; // Porcentaje de transacciones digitales de la entidad
  website: String; // sitio web de la entidad
  description: String; // descripción de la necesidad
  collaborators: CollaboratorsEnum; // cantidad de colaboradores 
  formalities: String;
  procedures: ProcedureEnum;
  policy: Boolean;
}

const myHelper: any = {
  name:{
    required: "El nombre es requerido",
    pattern: "El nombre es inválido"
  },
  enterprise:{
    required: "El nombre de la empresa o entidad es requerido",
    pattern: "El nombre de la empresa o entidad es inválido"
  },
  position:{
    required: "El cargo es requerido",
    pattern: "El cargo es inválido"
  },
  email: {
    required: "El correo es requerido",
    pattern: "Email inválido"
  },
  phone:{
    required: "El teléfono es requerido",
    pattern: "Número telefónico inválido"
  }
};

const Formulario: React.FC<FormInput> = () => {

  const [open, setIsOpen] = useState(false)
  const [dense, setDense] = React.useState(false);

  const { handleSubmit, reset, control } = useForm();

  const handleOnSubmit: SubmitHandler<FormInput> = data => {
    const url = 'https://hooks.zapier.com/hooks/catch/1396001/bef9qvd?';
    const date = new Date();
    const params = `
    name=${data.name} 
    &position=${data.position} 
    &email=${data.email} 
    &phone=${data.phone} 
    &company=${data.enterprise} 
    &url=${data.website}
    &formailities=${data.formalities}
    &procedures=${data.procedures}
    &collaborators=${data.collaborators}
    &description=${data.description}
    &date=${date.toLocaleString()}
    `;
    
    axios.post(url + params, {}, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(res => {
      if(res.status === 200) {
        sendAnalyticsEvent();
        setIsOpen(true)
        reset()
      }
    })
  }
  const sendAnalyticsEvent = () => {
    if(typeof window !== 'undefined') {
      window.gtag('event', `Clic - Formulario`, {
        'event_category': 'Contacto Ventas',
        'event_label': `Contacto Ventas - Formulario`,
      })
    }
    return true
  }

  return (
    <>
      <Grid className="form-ingenio" container item lg={12} md={12} sm={12} xs={12}>
      <Grid container item lg={2} md={2} sm={0} xs={0}></Grid>
        <Grid container item lg={3} md={3} sm={12} xs={12}>
          <Box className="form-text-content" px={{xs:'2rem', md:'none'}} ml={{xs:'1rem', md:'none'}}>
            <Typography color="text.dark" variant="h1">
              Te ayudamos a
            </Typography>
            <Typography color="text.alternate" variant="h1">
              <b>impulsar tu negocio</b>
            </Typography>
            <Box sx={{display: {sm: 'none', md: 'block' }}}>
              <ul>
                <li className="form-desc-list">Ofrecemos soluciones creativas, flexibles y personalización acorde a las necesidades del negocio.</li>
                <li className="form-desc-list">Contamos con tecnología que simplifica y automatiza procesos, aumentando la productividad y aglizando procesos para sus clientes.</li>
                <li className="form-desc-list">Impulsamos la transformación digital en la región tanto para sectores públicos como privados.</li>
              </ul>
            </Box>
          </Box>
        </Grid>
        <Grid container item lg={6} md={6} sm={12} xs={12}>
            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} className="form">
              <Typography variant="h5" className="form-form-title">Déjanos tus datos y te contactaremos en breve para conocer tus necesidades</Typography>
                <Grid container spacing={{ xs:'none', sm: 'none', md: 2 }} item={true} md={12} sm={12} xs={12}>
                  <Box className="row input-containers" sx={{mt:{md:4, sm: 0, xs:0}}}>
                    <Controller
                      name={"name"}
                      control={control}
                      rules={{
                        required: true,
                        //pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          className="input"
                          {...field}
                          defaultValue=""
                          label="Nombre y Apellido"
                          variant="outlined"
                          required={true}
                          error={error !== undefined}
                          helperText={error ? myHelper.name[error.type] : ""}
                        />
                      )}
                    />
                    <div className="spacing"></div>
                    <Controller
                      name={"enterprise"}
                      control={control}
                      rules={{
                        required: true,
                       //pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                      }}
                      render={({ field, fieldState: { error }}) => (
                        <TextField
                          className="input"
                          {...field}
                          defaultValue=""
                          label="Nombre de la empresa o entidad" 
                          variant="outlined"
                          required={true}
                          error={error !== undefined}
                          helperText={error ? myHelper.enterprise[error.type] : ""}
                        />
                      )}
                    />
                  </Box>
                  <Box className="row input-containers" sx={{mt:{md:4, sm: 0, xs:0}}}>
                    <Controller
                      name={"position"}
                      control={control}
                      rules={{
                        required: true,
                        //pattern: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          className="input"
                          {...field}
                          defaultValue=""
                          label="Cargo"
                          variant="outlined"
                          required={true}
                          error={error !== undefined}
                          helperText={error ? myHelper.enterprise[error.type] : ""}
                        />
                      )}
                    />
                    <div className="spacing"></div>
                    <Controller
                      name={"website"}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          className="input"
                          {...field}
                          defaultValue=""
                          label="Sitio web de la empresa o entidad" 
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                  <Box className="row input-containers" sx={{mt:{md:4, sm: 0, xs:0}}}>
                    <Controller
                      name={"email"}
                      control={control}
                      rules={{
                        required: true,
                        // pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                      }}
                      render={({ field, fieldState: { error }}) => (
                        <TextField
                          className="input"
                          {...field}
                          defaultValue=""
                          label="Correo corporativo"
                          variant="outlined"
                          required={true}
                          error={error !== undefined}
                          helperText={error ? myHelper.email[error.type] : ""}
                        />
                      )}
                    />
                    <div className="spacing"></div>
                    <Controller
                      name={"phone"}
                      control={control}
                      rules={{
                        required: true,
                        pattern: /^[\(]?[\+]?(\d{2}|\d{3})[\)]?[\s]?((\d{6}|\d{8})|(\d{3}[\*\.\-\s]){2}\d{3}|(\d{2}[\*\.\-\s]){3}\d{2}|(\d{4}[\*\.\-\s]){1}\d{4})|\d{8}|\d{10}|\d{12}$/
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          className="input"
                          {...field}
                          defaultValue=""
                          label="Número de teléfono" 
                          variant="outlined"
                          required={true}
                          error={error !== undefined}
                          helperText={error ? myHelper.phone[error.type] : ""}
                        />
                      )}
                    />
                  </Box>
                  <Box className="input-containers-full">
                    <Controller
                        name={"formalities"}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            className="input-full"
                            fullWidth
                            {...field}
                            defaultValue=""
                            label="¿Cuál es el promedio de transacciones totales mensuales de la entidad?" 
                            variant="outlined"
                          />
                        )}
                      />
                    <Controller
                      control={control}
                      name={"procedures"}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl fullWidth className="input-full">
                          {/* <InputLabel id="procedures-select-label">¿Qué porcentaje de transacciones mensuales son digitales?</InputLabel> */}
                          <FormHelperText>¿Qué porcentaje de transacciones mensuales son digitales?</FormHelperText>
                          <Select labelId="procedures-select-label"  {...field} placeholder="¿Cuál es el número de colaboradores en la empresa o entidad?">
                            {procedures.map((item) => (
                              <MenuItem key={item} value={item}>{item}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    <Controller
                      control={control}
                      name={"collaborators"}
                      defaultValue = "" 
                      render={({ field }) => (
                        <FormControl fullWidth  className="input-full">
                          {/* <InputLabel id="collaborators-select-label">¿Cuál es el número de colaboradores en la empresa o entidad?</InputLabel> */}
                          <FormHelperText>¿Cuál es el número de colaboradores en la empresa o entidad?</FormHelperText>
                          <Select labelId="collaborators-select-label" {...field}  placeholder="¿Cuál es el número de colaboradores en la empresa o entidad?">
                            {collaborators.map((item) => (
                              <MenuItem key={item} value={item}>{item}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    <Controller
                      name={"description"}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          className="input-full"
                          fullWidth
                          {...field}
                          defaultValue=""
                          label="Cuéntanos tu necesidad" 
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>
              <Grid item sx={{mx: 'auto', width: 100}}>
                <ModalMessage modalIsOpen={open} setIsOpen={setIsOpen}/>
              </Grid>
            </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{display: {sm: 'block', md: 'none', lg: 'none' }}}>
          <Box>
              <ul>
                <li className="form-desc-list">Ofrecemos soluciones creativas, flexibles y personalización acorde a las necesidades del negocio.</li>
                <li className="form-desc-list">Contamos con tecnología que simplifica y automatiza procesos, aumentando la productividad y aglizando procesos para sus clientes.</li>
                <li className="form-desc-list">Impulsamos la transformación digital en la región tanto para sectores públicos como privados.</li>
              </ul>
            </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Formulario